<template>
    <div class="login_main">
        <div class="all_box">
            <div class="container">
                <div class="container_left">
                    <img class="logo" src="../../assets/img/login/logo_text.png" height="33" width="115"/>
                    <div class="line1">专业的SEM竞品分析平台</div>
                    <div class="line2">AI竞投帮助您全面了解竞品、持续跟踪竞品</div>
                    <div class="line3">素材创意更丰富  广告投放更高效<br>快速增加流量  有效提升ROI</div>
                    <img class="img" src="../../assets/img/login/logo_img.png" height="225" width="293"/>
                </div>
                <div class="container_right">
                    <div class="header">
                        <img class="logo" src="../../assets/img/login/logo.png" />
                        <div class="subhead">
                            <img src="../../assets/img/login/success_icon.png" style="margin-right: 6px" height="16" width="16"/>
                            <span>注册成功！完善以下信息可以获得更好的体验</span>
                        </div>
                    </div>
                    <div class="input_company">
                        <div class="title">一、自动推送监测动态</div>
                        <div class="ewm">
                            <div class="ewm_img">
                                <img :src="ewm_url" height="118" width="118"/>
                            </div>
                            <span class="text">扫一扫绑定微信，您监测对象的动态将自动推送</span>
                        </div>
                        <div class="title">二、自动找出竞争对手 <span style="color: #ef3819;">*</span></div>
                        <el-autocomplete
                            style="width: 100%; margin-top: 16px; margin-bottom: 21px"
                            v-model="company_name"
                            value-key="company_name"
                            popper-class="login_popover"
                            :trigger-on-focus="false"
                            :fetch-suggestions="querySearchAsync"
                            placeholder="请输入完整公司名，自动找出推广竞争对手">
                        </el-autocomplete>
                        <myButton
                            type="primary"
                            width="410"
                            height="50"
                            fontsize="18"
                            @click="submit">
                            <span slot="text">提交</span>
                        </myButton>
                    </div>
                </div>
            </div>
            <div class="footer">
                <span>Copyright© 2021-2024 上海凭安网络科技有限公司 AI竞投（aijingtou.com）. All Rights Reserved. <span @click="toLink">沪ICP备12039960号-38</span></span>
            </div>
        </div>

    </div>
</template>

<script>
  import myButton from "@/components/myButton/myButton";
  import { update_holder_name} from "@/api/login";
  import {get_wx_bind_qrcode} from "@/api/monitor";
  import PinganAna from 'pingansec-vue-ana'
  import * as api from "@/api/search"
  export default {
    name: "completeInformation",
    components: {
      myButton,
    },
    data() {
      return {
        company_name: '',
        ewm_url: ''
      }
    },
    created() {
      this.getWXEWM()
    },
    methods: {
      querySearchAsync(queryString, cb) {
        if(queryString) {
          let params = {
            keyword: queryString,
            type: 1
          };
          api.get_ad_compete_list_search(params).then(res => {
            if(res.data.result_code == 0) {
              cb(res.data.data);
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        }
      },
      getWXEWM() {
        get_wx_bind_qrcode().then(res =>{
          if(res.data.result_code==0) {
            this.ewm_url = res.data.data.url
          }
        })
      },
      toLink() {
        window.open('https://beian.miit.gov.cn/#/Integrated/index','_blank')
      },
      alert(txt, type) {
        this.$message.closeAll();
        this.$message({
          message: txt,
          type: type,
        });
      },
      submit() {
        if(!this.company_name) {
          this.$message({
            type: 'error',
            message: '公司名不能为空'
          })
          return
        }
        update_holder_name({holder_auth_name: this.company_name,}).then(res => {
          if(res.data.result_code == 0) {
            this.$message({
              type: 'success',
              message: '提交成功'
            })
            PinganAna.ready(() => {
              let source = '';
              if(localStorage.getItem('ajt_source')) {
                source = this.$C.sourceTurn(localStorage.getItem('ajt_source'))
              }
              PinganAna.userClickLog(
                '注册提交',
                '{"side":"pc","activity":"'+source+'"}',
                '【AI竞投】系统',
                3,
                '--',
                this.$route.query.phone
              )
            })
            this.$router.push('/box/dataView');
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
    .login_main {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: url("../../assets/img/login/logo_bg.png");
        background-size: cover;
        .all_box {
            .container {
                width: 926px;
                box-shadow: 0px 3px 25px 0px
                rgba(21, 62, 112, 0.1);
                display: flex;
                .container_left {
                    width: 317px;
                    background: url("../../assets/img/login/img_bg.png");
                    background-size: cover;
                    padding: 56px 40px 36px 40px;
                    color: #fff;
                    .logo {
                        margin-bottom: 19px;
                    }
                    .line1 {
                        font-size: 24px;
                        font-weight: bold;
                        margin-bottom: 17px;
                    }
                    .line2 {
                        font-size: 16px;
                        opacity: 0.7;
                        margin-bottom: 45px;
                    }
                    .line3 {
                        font-size: 18px;
                        line-height: 35px;
                        margin-bottom: 70px;
                    }
                    .img {
                        margin-left: 12px;
                    }
                }
                .container_right {
                    flex: 1;
                    background-color: #fff;
                    .header {
                        text-align: center;
                        padding: 40px 0 27px 0;
                        border-bottom: 1px solid #e0e6ed;
                        .logo {
                            width: 197px;
                            height: 57px;
                        }
                        .subhead {
                            position: relative;
                            color: #1f81f8;
                            font-size: 16px;
                            margin-top: 34px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            strong {
                                font-weight: bold;
                            }
                            .hot {
                                width: 16px;
                                height: 16px;
                                position: absolute;
                                right: 212px;
                                top: -14px;
                            }
                        }
                    }
                    .login_box {
                        padding: 40px 58px;
                        font-size: 18px;
                        color: #333333;
                        .login_tab {
                            position: relative;
                            margin-bottom: 55px;
                            margin-left: 14px;
                            .tab_item {
                                display: inline-block;
                                margin-right: 40px;
                                cursor: pointer;
                            }
                            .active {
                                color: #1f81f8;
                            }
                            .line {
                                position: absolute;
                                display: inline-block;
                                height: 2px;
                                left: 0;
                                top: 31px;
                                transition: all .5s;
                                background-color: #1f81f8;
                            }
                        }
                        .code_line {
                            margin-top: 24px;
                            margin-bottom: 25px;
                            .el-input {
                                width: 270px;
                                margin-right: 6px;
                            }
                            .code_btn {
                                width: 136px;
                                display: inline-block;
                                box-sizing: border-box;
                                font-size: 16px;
                                color: #1f81f8;
                                padding: 17px 0;
                                text-align: center;
                                border-radius: 6px;
                                border: solid 1px #1f81f8;
                                cursor: pointer;
                                &:hover {
                                    color: #fff;
                                    background-color: #1f81f8;
                                }
                                &:active {
                                    color: #fff;
                                    background-color: #1f81f8;
                                    opacity: 0.8;
                                }
                            }
                        }
                        .notice {
                            @extend .width-normal;
                            margin-top: 25px;
                            display: flex;
                            align-items: center;

                            img {
                                width: 14px;
                                height: 14px;
                                margin-right: 10px;
                                cursor: pointer;
                            }

                            span {
                                @extend .font-normal;
                                color: #333333;

                                .agreement {
                                    color: #1f81f8;
                                    cursor: pointer;
                                }
                            }
                        }
                        .tip {
                            margin-top: 19px;
                            font-size: 14px;
                            color: #999999;
                        }
                    }
                    .input_company {
                        padding: 25px 59px 40px 59px;
                        .ewm {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            margin-top: 22px;
                            margin-bottom: 30px;
                            .ewm_img {
                                width: 120px;
                                height: 120px;
                                background: url("../../assets/img/login/angle.png");
                                background-size: cover;
                                display: inline-flex;
                                align-items: center;
                                justify-content: center;
                            }
                            .text {
                                margin-top: 13px;
                                color: #999;
                                font-size: 14px;
                            }
                        }
                        .title {
                            font-size: 14px;
                            color: #333333;
                            margin-left: 6px;
                        }
                    }
                }
            }
            .footer {
                position: relative;
                left: calc(50% - 258px);
                bottom: -100px;
                font-size: 13px;
                color: #999999;
            }
        }


        .dialog-content {
            h3,
            p {
                @extend .font-normal;
                line-height: 32px;
                color: #666666;
            }
        }
    }
</style>

<style lang="scss">
    .login_popover {
        .el-autocomplete-suggestion__wrap {
            max-height: 200px;
        }
    }
    .login_main  {
        .el-input {
            .el-input__inner {
                height: 50px;
            }
        }
    }
</style>
