import http from '@/utils/http'

// 加入监测
export function add_monitor(data) {
    return http({
        url: '/u-base/ac-add_monitor',
        method: 'get',
        params: data
    })
}

// 取消监测
export function cancel_monitor(data) {
    return http({
        url: '/u-base/ac-cancel_monitor',
        method: 'get',
        params: data
    })
}

// 获取监测列表-公司
export function get_monitor_company_list(data) {
    return http({
        url: '/u-base/ac-get_monitor_company_list',
        method: 'get',
        params: data
    })
}

// 获取监测列表-词
export function get_monitor_word_list(data) {
    return http({
        url: '/u-base/ac-get_monitor_word_list',
        method: 'get',
        params: data
    })
}

// 获取监测列表结果-公司名
export function get_monitor_company_result_list(data) {
    return http({
        url: '/u-base/ac-get_monitor_company_result_list',
        method: 'get',
        params: data
    })
}

// 获取监测列表结果-词
export function get_monitor_word_result_list(data) {
    return http({
        url: '/u-base/ac-get_monitor_word_result_list',
        method: 'get',
        params: data
    })
}

// 获取监测列表结果-已读
export function read_monitor_result(data) {
    return http({
        url: '/u-base/ac-read_monitor_result',
        method: 'get',
        params: data
    })
}

// 获取微信通知状态
export function get_val_by_key(data) {
    return http({
        url: '/u-base/ac-get_val_by_key',
        method: 'get',
        params: data
    })
}

// 改变微信通知状态
export function set_val_by_key(data) {
    return http({
        url: '/u-base/ac-set_val_by_key',
        method: 'get',
        params: data
    })
}

// 解绑微信
export function unbind_wx(data) {
    return http({
        url: '/u-base/ac-unbind_wx',
        method: 'get',
        params: data
    })
}

// 获取微信绑定二维码
export function get_wx_bind_qrcode(data) {
    return http({
        url: '/u-base/ac-get_wx_bind_qrcode',
        method: 'get',
        params: data
    })
}

//获取用户信息
export function get_user_info(data) {
    return http({
        url: '/u-base/ac-get_user_info',
        method: 'get',
        params: data
    })
}

//获取监控統計數據
export function get_monitor_report(data) {
    return http({
        url: '/u-base/ac-get_monitor_report',
        method: 'get',
        params: data
    })
}


//获取监控列表结果
export function get_monitor_list(data) {
    return http({
        url: '/u-base/ac-get_monitor_list',
        method: 'get',
        params: data
    })
}

//标为已读
export function read_monitor_result_v2(data) {
    return http({
        url:'/u-base/ac-read_monitor_result_v2',
        transformRequest: [(data) => {
            return data
        }],
        header: {
            'Content-Type': 'application/json'
        },
        method: 'post',
        data: JSON.stringify(data)
    })
}
